import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiCalls from "../../../../api";
import { store } from "../../../../store";
import { clearEmailToVerify, setToken, setUser } from "../../../../store/user-store";
import AuthHelper from "../../../../utils/auth-helper";
import GeneralUtils from "../../../../utils/general-utils";
import "./email-auth-verify-page.scss";

const EmailAuthVerifyPage = () => {
  const history = useHistory();
  const {emailToVerify} = useSelector(state => state.user);

  const _processLogin = async () => {
    // this.props.showLoader("Logging you in");
    // store.dispatch(showLoader("Logging you in"));
    // const email = window.localStorage.getItem("emailForSignIn");
    // AuthHelper.initialize();
    await GeneralUtils.sleepTime(2000);
    const firebaseClientToken = await AuthHelper.getTokenFromLoginLink(
      window.location.href,
      emailToVerify
    );
    GeneralUtils.showLoader(true);
    if (firebaseClientToken?.length) {
      try {
        const { data: {data: userData} } = await ApiCalls.user.public.exchangeToken({
          authToken: firebaseClientToken,
        });
        console.log('userData', userData)
        store.dispatch(clearEmailToVerify());
        store.dispatch(setUser(userData.userData));
        store.dispatch(setToken(userData.token));
        // this.props.hideLoader();
        // Success login
        history.replace("/home");
      } catch (error) {
        console.log('error', error)
        // const { data: userData } = await createUser({
        //   authToken: firebaseClientToken,
        //   firstName: "",
        //   lastName: "",
        //   state: "",
        //   zip: "",
        //   country: "",
        //   phone: "",
        //   companyName: "",
        //   email: email,
        // });
        // store.dispatch(
        //   updateUserData({
        //     ...userData.userData,
        //     token: userData.token,
        //   })
        // );
        history.replace("/");
      }
    } else {
      history.replace("/");
    }
    GeneralUtils.showLoader(false);
  };

  useEffect(() => {
    _processLogin();
  }, []);

  return <>Logging you in</>;
};

export default EmailAuthVerifyPage;
