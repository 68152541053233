import React, { useState } from "react";
import ICONS from "../../../../icons-lib";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login-page.scss";
import AuthHelper from "../../../../utils/auth-helper";
import { store } from "../../../../store";
import { setEmailToVerify } from "../../../../store/user-store";

const LoginPage = () => {
  const [activeView, setActiveView] = useState("email-input"); // Other option = 'sent-success'
  const [emailInput, setEmailInput] = useState("");

  const _sendEmailLink = async () => {
    let isValid = false;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(emailInput.toLowerCase())) {
      isValid = true;
    }
    if (isValid) {
      store.dispatch(
        setEmailToVerify(emailInput)
      )
      AuthHelper.generateLoginLink(emailInput);
      setActiveView("sent-success");
    } else {
      toast.error("Please provide a valid email");
    }
  };

  const _renderActiveView = () => {
    if (activeView === "email-input") {
      return (
        <>
          <div className="right">
            <div className="form-title font-heading text-heading text-3xl">
              Welcome!
            </div>
            <div className="form-desc text-size-body my-2">
              Access your account from here
            </div>

            {/************** LOGIN */}
            <div className={"login-form "}>
              <div className="form-wrapper mx-auto my-8 max-w-lg">
                <div className="input-group relative h-16 flex items-center my-4">
                  <div className="icon absolute left-0 top-1/2">
                    {ICONS.SVG_Email}
                  </div>
                  <input
                    type="email"
                    className="form-control outline-0 w-full border-b-2 h-full text-size-body pl-12"
                    placeholder="Enter you email here"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                  />
                </div>
              </div>

              <div className="buttons my-4">
                <div className="button fancy" onClick={_sendEmailLink}>
                  <span>Get link</span>
                  {ICONS.SVG_Arrow_curly}
                </div>
              </div>
            </div>
            {/************** /LOGIN */}
          </div>
        </>
      );
    }
    if (activeView === "sent-success") {
      return (
        <>
          <div className="right">
            <div className="form-title font-heading text-heading text-3xl">
              Login link has been emailed to you!
            </div>
            <div className="form-desc text-size-body my-2">
              <>
                Click on the login link that has been to your email at{" "}
                <b>{emailInput}</b>
                <div className="mt-5 text-xs font-semibold">
                  Wrong email? <br />
                  <span
                    className="text-blue-500 underline hover:no-underline cursor-pointer"
                    onClick={() => setActiveView("email-input")}
                  >
                    Re-enter your email here
                  </span>
                </div>
              </>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="login-page-wrapper min-h-screen bg-color_primary_light flex items-center justify-center">
        <div className="inner-wrapper container bg-white grid grid-cols-1 md:grid-cols-2 gap-4 p-8 items-center rounded-3xl">
          <div className="left">
            <div className="image-wrapper logo mx-auto w-64 h-24">
              <img
                src={require("../../../../images/logo.png")}
                alt=""
                className="w-full h-full object-contain"
              />
            </div>
            <div className="big-title font-heading text-size-big my-8 text-heading">
              Get started with Aveson Pulse
            </div>
            <div className="desc my-8 text-size-body">
              {/* Praesent sapien massa, convallis a pellentesque nec, egestas{" "}
              <br /> non nisi. Nulla porttitor accumsan tincidunt. */}
            </div>

            <div className="social-icons flex items-center justify-center my-8">
              <div className="item cursor-pointer mx-2 rounded-lg">
                {ICONS.SVG_Facebook}
              </div>
              <div className="item cursor-pointer mx-2 rounded-lg">
                {ICONS.SVG_Instagram}
              </div>
              <div className="item cursor-pointer mx-2 rounded-lg">
                {ICONS.SVG_Twitter}
              </div>
            </div>

            {/* <div className="download-app-buttons flex items-center justify-center">
              <div className="image-wrapper mx-1">
                <img
                  src={require("../../../../images/google-play.png")}
                  alt=""
                />
              </div>
              <div className="image-wrapper mx-1">
                <img
                  src={require("../../../../images/apple-store.png")}
                  alt=""
                />
              </div>
            </div> */}
          </div>

          {_renderActiveView()}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
