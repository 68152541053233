import React from "react";
import ICONS from "../../../../../../icons-lib";
import SurveyUtils from "../../survey-utils";

const StudentSurveyCard = ({ schoolSurvey }) => {
  const status = SurveyUtils.getSchoolSurveyStatus(schoolSurvey);
  return (
    <>
      <div
        className="card card-style-1 p-4 cursor-pointer duration-200 rounded-lg relative top-0 left-0 hover:-top-2"
        onClick={(e) => SurveyUtils.openSchoolSurvey(schoolSurvey)}
        style={
          status.isCompleted
            ? {
                pointerEvents: "none",
              }
            : null
        }
      >
        <div className="inner grid gap-4 items-center">
          <div className="left image-wrapper rounded-lg overflow-hidden h-48 w-full">
            <img
              //   src="https://as1.ftcdn.net/v2/jpg/02/57/31/86/1000_F_257318676_n1WcFklWdixCa4asoQrLsd9CWrCZk3yO.jpg"
              alt=""
              src={require("../../../../../../images/school-icon.jpeg")}
              className="w-full h-full object-cover"
            />
          </div>

          <div className="right text-left">
            <div className="title font-semibold text-size-heading-small text-heading duration-200">
              {schoolSurvey.surveyTitle}
            </div>
            <div className="desc text-size-body my-4">
              {schoolSurvey.surveyDesc}
            </div>
            <div className="posted-on text-size-body-small my-4 opacity-50">
              Posted on: 26th March, 2023
            </div>

            <div className="buttons text-right">
              <div className="progress grid gap-4">
                <div className="track rounded-full">
                  <span
                    class="complete"
                    style={{
                      "--complete-percent": `${status.progressPercentage}%`,
                    }}
                  ></span>
                </div>
                <span className="percent">{status.progressPercentage}%</span>
              </div>
              <div
                className="button fancy"
                style={
                  status.isCompleted
                    ? {
                        opacity: "0.2",
                        pointerEvents: "none",
                      }
                    : null
                }
              >
                <span>
                  {status.isStarted && !status.isCompleted && "Continue"}{" "}
                  {status.isCompleted && "Complete!"}{" "}
                  {!status.isStarted && "Start"}
                </span>
                {!status.isCompleted && ICONS.SVG_Arrow_curly}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentSurveyCard;
