import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allSurveys: {},
  activeSurveyEditor: {
    survey: null,
    teacher: null,
    activeQuestionIndex: 0,
    questions: [],
  },
};

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setAllSurveys: (state, action) => {
      state.allSurveys = action.payload;
    },
    setActiveSurveyEditor: (state, action) => {
      state.activeSurveyEditor = action.payload;
    },
    clearActiveSurveyEditor: (state) => {
      state.activeSurveyEditor = {
        survey: null,
        teacher: null,
        activeQuestionIndex: 0,
        questions: [],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllSurveys, setActiveSurveyEditor, clearActiveSurveyEditor } =
  surveySlice.actions;

export default surveySlice.reducer;
