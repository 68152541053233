import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ICONS from "../../../../icons-lib";
import GeneralUtils from "../../../../utils/general-utils";
import MySurveyCard from "../../entities/survey/components/my-survey-card/my-survey-card";
import StudentSurveyCard from "../../entities/survey/components/student-survey-card/student-survey-card";
import SurveyViewer from "../../entities/survey/components/survey-viewer/survey-viewer";
import TeacherSurveyCard from "../../entities/survey/components/teacher-survey-card/teacher-survey-card";
import SurveyUtils from "../../entities/survey/survey-utils";
import UserProfileEditorPopup from "../../entities/user/components/user-profile-editor-popup/user-profile-editor-popup";
import Card from "../includes/card/card";
import Header from "../includes/header/header";
import "./dashboard.scss";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("all-survey"); // 'participated-survey'
  const { allSurveys, activeSurveyEditor } = useSelector(
    (state) => state.survey
  );

  const [studentCategoryOpen, setStudentCategoryOpen] = useState(true);
  const [teacherCategoryOpen, setTeacherCategoryOpen] = useState(true);

  const allParticipatedSurveys = SurveyUtils.getAllParticipatedSurveys();
  const allNoncompletedSurveys = SurveyUtils.filterOutNonCompletedSurveys(allSurveys);

  const _renderActiveTab = () => {
    switch (activeTab) {
      case "all-survey": {
        const { schoolSurveys, teacherSurveys } = allNoncompletedSurveys;
        return (
          <>
            <div className={"conten1 all-survey-content "}>
              <div
                className={
                  "survey-category mb-10 student-survey " +
                  (studentCategoryOpen ? "open" : "")
                }
              >
                <div
                  className="content-title flex justify-between items-center p-5 mb-8 cursor-pointer duration-300 bg-secondary-light hover:bg-secondary-dark rounded-3xl"
                  onClick={(e) => setStudentCategoryOpen(!studentCategoryOpen)}
                >
                  <span className="font-heading text-2xl flex items-center gap-3">
                    {ICONS.SVG_student} School Survey
                  </span>
                  <span className="icon">{ICONS.SVG_chevron_down}</span>
                </div>
                <div className="content-inner grid grid-cols-2 gap-16">
                  {schoolSurveys?.map((schoolSurvey) => (
                    <StudentSurveyCard
                      key={schoolSurvey._id}
                      schoolSurvey={schoolSurvey}
                    />
                  ))}
                </div>
              </div>

              <div
                className={
                  "survey-category mb-10 teacher-survey " +
                  (teacherCategoryOpen ? "open" : "")
                }
              >
                <div
                  className="content-title flex justify-between items-center p-5 mb-8 cursor-pointer duration-300 bg-secondary-light hover:bg-secondary-dark rounded-3xl"
                  onClick={(e) => setTeacherCategoryOpen(!teacherCategoryOpen)}
                >
                  <span className="font-heading text-2xl flex items-center gap-3">
                    {ICONS.SVG_teacher} Teacher survey
                  </span>
                  <span className="icon">{ICONS.SVG_chevron_down}</span>
                </div>
                <div className="content-inner grid grid-cols-2 gap-16">
                  {teacherSurveys?.map((teacherSurvey) => (
                    <TeacherSurveyCard
                      key={teacherSurvey._id}
                      teacherSurvey={teacherSurvey}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      }
      case "participated-survey": {
        return (
          <>
            <div className={"conten2 inprogress-survey-content "}>
              <div className="content-inner grid grid-cols-2 gap-16">
                {allParticipatedSurveys?.map(
                  (participatedSurvey, participatedSurveyIndex) => (
                    <MySurveyCard
                      survey={participatedSurvey}
                      key={participatedSurveyIndex}
                    />
                  )
                )}
              </div>
            </div>
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  useEffect(() => {
    GeneralUtils.hideLoader();
    SurveyUtils.loadAllSurveys();
  }, []);

  return (
    <>
      <Header />
      <UserProfileEditorPopup />
      {activeSurveyEditor.survey ? (
        <>
          <SurveyViewer />
        </>
      ) : (
        <>
          <div className="dashboard-page-wrapper bg-color_primary_light flex items-center justify-center mb-32">
            <div className="inner-wrapper container py-8">
              <div className="tabs grid grid-cols-2 my-10">
                <div
                  className={
                    "tab tab1 all-survey flex items-center justify-center opacity-50 cursor-pointer py-4 " +
                    (activeTab === "all-survey" ? "active" : "")
                  }
                  onClick={(e) => setActiveTab("all-survey")}
                >
                  {ICONS.SVG_Bar_chart}
                  <span className="text-size-heading-small font-heading ml-4">
                    Active surveys
                  </span>
                </div>
                <div
                  className={
                    "tab tab2 all-survey flex items-center justify-center opacity-50 cursor-pointer py-4 " +
                    (activeTab === "participated-survey" ? "active" : "")
                  }
                  onClick={(e) => setActiveTab("participated-survey")}
                >
                  {ICONS.SVG_User}
                  <span className="text-size-heading-small font-heading ml-4">
                    Completed Surveys
                  </span>
                </div>
              </div>

              <div className="tab-contents">{_renderActiveTab()}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
