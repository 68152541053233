import React, { Component, useEffect, useState } from "react";
import { EventEmitter } from "../../utils/event-emitter";
import "./fullpage-loader.scss";

const FullpageLoader = () => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  useEffect(() => {
    EventEmitter.subscribe("SHOW_LOADER", () => {
      setIsLoaderActive(true);
    });
    EventEmitter.subscribe("HIDE_LOADER", () => {
      setIsLoaderActive(false);
    });
  }, []);
  return (
    <>
      {isLoaderActive ? (
        <div className="loaderWrapper pageLoader">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FullpageLoader;
