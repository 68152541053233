import ApiCalls from "../../../../api";
import { store } from "../../../../store";
import {
  clearActiveSurveyEditor,
  setActiveSurveyEditor,
  setAllSurveys,
} from "../../../../store/survey-store";
import GeneralUtils from "../../../../utils/general-utils";
import deepClone from "clonedeep";

const SurveyUtils = {
  loadAllSurveys: async () => {
    const { user } = store.getState();
    if (user?.user?.gradeLevel?.length) {
      GeneralUtils.showLoader();
      const {
        data: {
          data: { surveys },
        },
      } = await ApiCalls.survey.private.getAllSurveys();
      store.dispatch(setAllSurveys(surveys));
      GeneralUtils.hideLoader();
    }
  },
  openSchoolSurvey: (survey) => {
    const formattedQuestions = SurveyUtils._formatSchoolSurveyQuestions(survey);
    store.dispatch(
      setActiveSurveyEditor({
        survey,
        activeQuestionIndex: 0,
        questions: formattedQuestions,
      })
    );
  },
  openTeacherSurvey: ({ survey, teacher }) => {
    const formattedQuestions = SurveyUtils._formatTeacherSurveyQuestions({
      survey,
      teacher,
    });
    store.dispatch(
      setActiveSurveyEditor({
        survey,
        teacher,
        activeQuestionIndex: 0,
        questions: formattedQuestions,
      })
    );
  },
  recordAnswer: ({ questionId, optionId }) => {
    const { survey: surveyStore } = store.getState();
    const {
      activeSurveyEditor: { questions, teacher, activeQuestionIndex, survey },
    } = surveyStore;
    const questionsCopy = deepClone(questions);
    let selectedQuestion = questionsCopy.find(
      (question) => question.questionId === questionId
    );
    if (selectedQuestion) {
      selectedQuestion._hasAnswer = true;
      selectedQuestion._formattedOptions.forEach((option) => {
        if (option.optionId !== optionId) {
          option.isSelected = false;
        } else {
          option.isSelected = true;
        }
      });
      const selectedOption = selectedQuestion._formattedOptions.find(
        (option) => option.optionId === optionId
      );
      selectedOption.isSelected = true;
    }
    store.dispatch(
      setActiveSurveyEditor({
        survey,
        teacher,
        activeQuestionIndex,
        questions: questionsCopy,
      })
    );
    ApiCalls.survey.private.recordResponse({
      surveyId: survey._id,
      teacherId: teacher ? teacher._id : "",
      questionId,
      selectedOptionId: optionId,
    });
  },
  selectNewQuestion: ({ questionIndex }) => {
    const {
      survey: {
        activeSurveyEditor: { teacher, survey, questions },
      },
    } = store.getState();
    store.dispatch(
      setActiveSurveyEditor({
        survey,
        teacher,
        activeQuestionIndex: questionIndex,
        questions,
      })
    );
  },
  leaveSurvey: () => {
    store.dispatch(clearActiveSurveyEditor());
    SurveyUtils.loadAllSurveys();
  },
  canSubmit: () => {
    const {
      survey: {
        activeSurveyEditor: { questions },
      },
    } = store.getState();
    let canSubmit = true;
    questions.forEach((question) => {
      if (!question._hasAnswer) {
        canSubmit = false;
      }
    });
    return canSubmit;
  },
  getSchoolSurveyStatus: (schoolSurvey) => {
    const status = {
      isStarted: !!schoolSurvey.completionPercentage,
      isCompleted: schoolSurvey.completionPercentage === 100,
      progressPercentage: Math.round(schoolSurvey.completionPercentage) || 0,
    };
    return status;
  },
  getTeacherSurveyStatus: ({survey, teacher}) => {
    const status = {
      isStarted: !!teacher.completionPercentage,
      isCompleted: teacher.completionPercentage === 100,
      progressPercentage: Math.round(teacher.completionPercentage) || 0,
    };
    return status;
  },
  getAllParticipatedSurveys: () => {
    let allParticipatedSurveys = [];
    const {
      survey: {
        allSurveys: {
          teacherSurveys,
          schoolSurveys
        },
      },
    } = store.getState();
    allParticipatedSurveys = schoolSurveys?.filter(schoolSurvey => schoolSurvey.completionPercentage === 100);
    teacherSurveys?.forEach(teacherSurvey => {
      teacherSurvey.teachers.forEach(teacher => {
        if (teacher.completionPercentage > 0) {
          allParticipatedSurveys.push({
            ...teacher,
            ...teacherSurvey,
            teacher
          })
        }
      })
    })
    return allParticipatedSurveys;
  },
  filterOutNonCompletedSurveys: (allSurveys) => {
    console.log('allSurveys :>> ', allSurveys);
    const filteredSurveys = {
      teacherSurveys: [],
      schoolSurveys: allSurveys?.schoolSurveys?.filter(survey => !survey.completionPercentage || survey.completionPercentage < 100)
    };
allSurveys?.teacherSurveys?.forEach(teacherSurvey => {
  const filteredTeacherSurvey =  {
    ...teacherSurvey,
    teachers: teacherSurvey.teachers.filter(teacher => !teacher.completionPercentage || teacher.completionPercentage < 100)
  }
  filteredSurveys.teacherSurveys.push(filteredTeacherSurvey);
})
    return filteredSurveys;
  },
  _formatSchoolSurveyQuestions: (survey) => {
    let formattedQuestions = [];
    if (survey?.questions?.length) {
      formattedQuestions = survey.questions.map((question) => {
        const formattedQuestion = {
          ...question,
          _hasAnswer: SurveyUtils._checkIfAnswered({
            questionId: question.questionId,
            userResponses: survey.userResponses,
          }),
          _formattedOptions: SurveyUtils._formatOptions({
            question,
            userResponses: survey.userResponses,
          }),
        };
        return formattedQuestion;
      });
    }
    return formattedQuestions;
  },
  _formatTeacherSurveyQuestions: ({ survey, teacher }) => {
    let formattedQuestions = [];
    if (survey?.questions?.length) {
      formattedQuestions = survey.questions.map((question) => {
        const formattedQuestion = {
          ...question,
          _hasAnswer: SurveyUtils._checkIfAnswered({
            questionId: question.questionId,
            userResponses: teacher.userResponses,
          }),
          _formattedOptions: SurveyUtils._formatOptions({
            question,
            userResponses: teacher.userResponses,
          }),
        };
        return formattedQuestion;
      });
    }
    return formattedQuestions;
  },
  _checkIfAnswered: ({ questionId, userResponses }) => {
    let hasAnswered = false;
    if (userResponses?.length) {
      hasAnswered = !!userResponses.find(
        (userResponse) => userResponse.questionId === questionId
      );
    }
    return hasAnswered;
  },
  _formatOptions: ({ question, userResponses }) => {
    let formattedOptions = deepClone(question.options);
    if (userResponses?.length) {
      const selectedQuestionResponse = userResponses.find(
        (userResponse) => userResponse.questionId === question.questionId
      );
      if (selectedQuestionResponse) {
        formattedOptions.forEach((option) => {
          if (option.optionId === selectedQuestionResponse.selectedOptionId) {
            option.isSelected = true;
          }
        });
      }
    }
    return formattedOptions;
  },
};

export default SurveyUtils;
