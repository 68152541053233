import { HttpClient } from "../clients/http-client";

const { ProtectedHttpClient, PublicHttpClient } = HttpClient;

const SurveyApi = {
  public: {},
  private: {
    getAllSurveys: () => ProtectedHttpClient.get(`/get-all-surveys/`),
    recordResponse: (payload) =>
      ProtectedHttpClient.post(`/record-response/`, payload),
  },
};
export default SurveyApi;
