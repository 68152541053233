import React from "react";
import { useSelector } from "react-redux";
import ICONS from "../../../../../../icons-lib";
import SurveyUtils from "../../survey-utils";
import SurveyQuestionViewer from "../survey-question-viewer/survey-question-viewer";
import "./survey-viewer.scss";
const SurveyViewer = () => {
  const { activeSurveyEditor } = useSelector((state) => state.survey);
  const currentQuestion =
    activeSurveyEditor.questions[activeSurveyEditor.activeQuestionIndex];
  const { activeQuestionIndex, questions } = activeSurveyEditor;
  console.log('currentQuestion :>> ', currentQuestion);
  return (
    <>
      <div className="single-survey-page-wrapper bg-color_primary_light flex items-center justify-center mt-12 mb-32">
        <div className="container p-8">
          <div className="inner-wrapper grid gap-16 text-left">
            <div className="left">
              <div className="text-size-body text-body">Question 1</div>
              <div className="question text-size-heading text-heading my-6">
                {currentQuestion?.questionText}
              </div>
              <div className="options grid gap-6">
                {currentQuestion?._formattedOptions?.map((option) => (
                  <div
                    key={option.optionId}
                    className={`answer text-size-heading-small rounded-2xl p-8 duration-200 cursor-pointer ${
                      option.isSelected && "active"
                    } `}
                    onClick={(e) =>
                      SurveyUtils.recordAnswer({
                        questionId: currentQuestion.questionId,
                        optionId: option.optionId,
                      })
                    }
                  >
                    {option.optionText}
                  </div>
                ))}
              </div>

              <div className="nav flex gap-2 justify-end my-16">
                <div
                  className="prev flex items-center gap-4 cursor-pointer py-2 px-4 hover:bg-gray-200 duration-200 rounded-lg"
                  onClick={(e) =>
                    SurveyUtils.selectNewQuestion({
                      questionIndex: activeQuestionIndex - 1,
                    })
                  }
                  style={
                    activeQuestionIndex === 0
                      ? {
                          opacity: "0.2",
                          pointerEvents: "none",
                        }
                      : {}
                  }
                >
                  {ICONS.SVG_Arrow_left}{" "}
                  <span className="text-size-body">Previous</span>
                </div>
                <div
                  className="next flex items-center gap-4 cursor-pointer py-2 px-4 hover:bg-gray-200 duration-200 rounded-lg"
                  onClick={(e) =>
                    SurveyUtils.selectNewQuestion({
                      questionIndex: activeQuestionIndex + 1,
                    })
                  }
                  style={
                    activeQuestionIndex === questions.length - 1
                      ? {
                          opacity: "0.2",
                          pointerEvents: "none",
                        }
                      : {}
                  }
                >
                  <span className="text-size-body">Next</span>{" "}
                  {ICONS.SVG_Arrow_right}
                </div>
              </div>

              <div className="bottom-action flex items-center justify-between mt-32">
                <div className="button" onClick={SurveyUtils.leaveSurvey}>
                  <span>Leave survey</span>
                  {ICONS.SVG_Arrow_simple}
                </div>
                <div
                  className="button fancy"
                  style={
                    !SurveyUtils.canSubmit()
                      ? {
                          opacity: "0.2",
                          pointerEvents: "none",
                        }
                      : null
                  }
                  onClick={SurveyUtils.leaveSurvey}
                >
                  <span>Submit survey</span>
                  {ICONS.SVG_Arrow_curly}
                </div>
              </div>
            </div>

            <div className="right">
              <SurveyQuestionViewer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyViewer;
