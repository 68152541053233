import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ApiCalls from "../../../../../../api";
import ICONS from "../../../../../../icons-lib";
import { store } from "../../../../../../store";
import { setUser } from "../../../../../../store/user-store";
import { EventEmitter } from "../../../../../../utils/event-emitter";
import GeneralUtils from "../../../../../../utils/general-utils";
import SurveyUtils from "../../../survey/survey-utils";
import "./user-profile-editor-popup.scss";

const UserProfileEditorPopup = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useSelector((state) => state.user);

  const _showStickyModalIfInfoNotPresent = () => {
    if (!user?.gradeLevel?.length) {
      _showEditor();
    }
  };

  const onSubmit = async ({ gender, gradeLevel, ethnicity, courseOfStudy }) => {
    GeneralUtils.showLoader();
    try {
      const {
        data: {
          data: { user: userData },
        },
      } = await ApiCalls.user.private.updateUser({
        ...user,
        gender,
        gradeLevel,
        ethnicity,
        courseOfStudy,
      });
      // console.log('userData', userData)
      store.dispatch(setUser(userData));
      await SurveyUtils.loadAllSurveys();
      setIsVisible(false);
      toast.success("Profile updated successfully");
    } catch (error) {
      console.log("error", error);
      toast.error("Update failed");
    }
    GeneralUtils.hideLoader();
  };

  const _showEditor = () => {
    setValue("gender", user.gender);
    setValue("gradeLevel", user.gradeLevel);
    setValue("ethnicity", user.ethnicity);
    setValue("courseOfStudy", user.courseOfStudy);
    setIsVisible(true);
  };

  const _listenToEvents = () => {
    EventEmitter.subscribe("SHOW_PROFILE_EDITOR", () => {
      _showEditor();
    });
  };

  const _unlistenToEvents = () => {
    EventEmitter.cancelAll("SHOW_PROFILE_EDITOR");
  };

  useEffect(() => {
    _showStickyModalIfInfoNotPresent();
    _listenToEvents();
    return () => {
      _unlistenToEvents();
    };
  }, [user]);
  return (
    <>
      {isVisible ? (
        <>
          <form
            className={"modal collect-user-info-modal show"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="modal-inner">
              <div className="title font-heading text-3xl mb-3">
                {" "}
                Provide the following details to proceed{" "}
              </div>

              <div
                className={`form-group grid text-left mb-6 ${
                  errors.gender && "error"
                }`}
              >
                <label htmlFor="" className="font-semibold">
                  Gender
                </label>
                <select
                  name=""
                  id=""
                  className="form-control p-4 border rounded-2xl"
                  {...register("gender", {
                    required: true,
                    minLength: 2,
                  })}
                >
                  <option value="">Select your gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <div className="errorText">
                  {errors.gender && "This field is required"}
                </div>
              </div>

              <div
                className={`form-group grid text-left mb-6 ${
                  errors.gradeLevel && "error"
                }`}
              >
                <label htmlFor="" className="font-semibold">
                  Grade level
                </label>
                <select
                  name=""
                  id=""
                  className="form-control p-4 border rounded-2xl"
                  {...register("gradeLevel", {
                    required: true,
                    minLength: 2,
                  })}
                >
                  <option value="">Select your grade level</option>
                  <option value="3-5">3-5</option>
                  <option value="6-12">6-12</option>
                </select>
                <div className="errorText">
                  {errors.gradeLevel && "This field is required"}
                </div>
              </div>

              <div
                className={`form-group grid text-left mb-6 ${
                  errors.ethnicity && "error"
                }`}
              >
                <label htmlFor="" className="font-semibold">
                  Ethnicity
                </label>
                <input
                  type="text"
                  className="form-control p-4 border rounded-2xl"
                  placeholder="Enter your ethnicity"
                  {...register("ethnicity", {
                    required: true,
                    minLength: 2,
                  })}
                />
                <div className="errorText">
                  {errors.ethnicity && "This field is required"}
                </div>
              </div>

              <div
                className={`form-group grid text-left mb-6 ${
                  errors.courseOfStudy && "error"
                }`}
              >
                <label htmlFor="" className="font-semibold">
                  Course of study
                </label>
                <select
                  name=""
                  id=""
                  className="form-control p-4 border rounded-2xl"
                  {...register("courseOfStudy", {
                    required: true,
                    minLength: 2,
                  })}
                >
                  <option value="">Select</option>
                  <option value="ASL">ASL</option>
                  <option value="AGLA">AGLA</option>
                </select>
                <div className="errorText">
                  {errors.courseOfStudy && "This field is required"}
                </div>
              </div>

              <div className="buttons">
                <button className="button fancy" type="submit">
                  <span>Submit</span>
                  {ICONS.SVG_Arrow_curly}
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserProfileEditorPopup;
