import React from "react";
import ICONS from "../../../../../../icons-lib";
import SurveyUtils from "../../survey-utils";

const MySurveyCard = ({ survey }) => {
  const status = SurveyUtils.getSchoolSurveyStatus(survey);
  return (
    <>
      <div className="card card-style-3 p-4 cursor-pointer duration-200 rounded-lg relative top-0 left-0 hover:-top-2">
        <div className="inner gap-4 items-center">
          <div className="left image-wrapper rounded-lg overflow-hidden h-64 w-full">
            <img
              src={
                survey.teacher
                  ? "https://chronicle.brightspotcdn.com/dims4/default/fc6b904/2147483647/strip/true/crop/973x511+0+68/resize/1200x630!/quality/90/?url=http%3A%2F%2Fchronicle-brightspot.s3.amazonaws.com%2F14%2F9e%2F2784bde6490d92c48a8afd280e3f%2Finclusive-teaching-advice-guide-promo.png"
                  : "https://static.vecteezy.com/system/resources/previews/009/640/250/original/back-to-school-minimal-trendy-horizontal-poster-with-book-and-text-season-educational-advertising-background-orange-color-creative-flyer-design-flat-simple-minimalistic-eps-banner-vector.jpg"
              }
              alt=""
              className="w-full h-full object-cover"
            />
          </div>

          <div className="right text-left">
            <div className="title font-semibold text-size-heading-small text-heading my-4 duration-200">
              {survey.surveyTitle}
            </div>
            <div className="desc text-size-body my-4">{survey.surveyDesc}</div>
            <div className="posted-on text-size-body-small my-4 opacity-50 flex items-center justify-between">
              {survey.teacher ? (
                <span>Teacher Name: {survey.teacher.teacherName}</span>
              ) : (
                <span>Student Survey</span>
              )}
            </div>

            <div className="buttons grid gap-8 items-center justify-between">
              <div className="progress grid gap-4">
                <div className="track rounded-full">
                  <span
                    class="complete"
                    style={{
                      "--complete-percent": `${status.progressPercentage}%`,
                    }}
                  ></span>
                </div>
                <span className="percent">{status.progressPercentage}%</span>
              </div>

              <div
                className="button fancy"
                style={
                  status.isCompleted
                    ? {
                        opacity: "0.2",
                        pointerEvents: "none",
                      }
                    : null
                }
                onClick={(e) => {
                  if (survey?.surveyType === "school") {
                    SurveyUtils.openSchoolSurvey(survey);
                  } else {
                    SurveyUtils.openTeacherSurvey({
                      survey,
                      teacher: survey.teacher,
                    });
                  }
                }}
              >
                <span>
                  {status.isStarted && !status.isCompleted && "Continue"}{" "}
                  {status.isCompleted && "Complete!"}{" "}
                  {!status.isStarted && "Start"}
                </span>
                {!status.isCompleted && ICONS.SVG_Arrow_curly}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MySurveyCard;
