import React, { useState } from "react";
import ICONS from "../../../../../../icons-lib";
import TeacherSelector from "../teacher-selector/teacher-selector";

const TeacherSurveyCard = ({ teacherSurvey }) => {
  const [isTeacherSelectorVisible, setIsTeacherSelectorVisible] =
    useState(false);
  return (
    <>
      <div className="card card-style-2 p-4 cursor-pointer duration-200 rounded-lg relative top-0 left-0 hover:-top-2">
        <div className="inner gap-4 items-center">
          <div className="left image-wrapper rounded-lg overflow-hidden h-64 w-full">
            <img
              src="https://chronicle.brightspotcdn.com/dims4/default/fc6b904/2147483647/strip/true/crop/973x511+0+68/resize/1200x630!/quality/90/?url=http%3A%2F%2Fchronicle-brightspot.s3.amazonaws.com%2F14%2F9e%2F2784bde6490d92c48a8afd280e3f%2Finclusive-teaching-advice-guide-promo.png"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>

          <div className="right text-left">
            <div className="title font-semibold text-size-heading-small text-heading my-4 duration-200">
              {teacherSurvey.surveyTitle}
            </div>
            <div className="desc text-size-body my-4">
              {teacherSurvey.surveyDesc}
            </div>
            <div className="posted-on text-size-body-small my-4 opacity-50">
              Posted on: 25th March, 2023
            </div>

            <div className="buttons text-right">
              <div
                className="button fancy"
                onClick={(e) => setIsTeacherSelectorVisible(true)}
              >
                <span>Take survey</span>
                {ICONS.SVG_Arrow_curly}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeacherSelector
        isVisible={isTeacherSelectorVisible}
        teacherSurvey={teacherSurvey}
        onDismiss={(e) => setIsTeacherSelectorVisible(false)}
      />
    </>
  );
};

export default TeacherSurveyCard;
