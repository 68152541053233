import { HttpClient } from "../clients/http-client";

const { ProtectedHttpClient, PublicHttpClient } = HttpClient;

const UserApi = {
  public: {
    exchangeToken: (payload) =>
    PublicHttpClient.post(`/exchange-token/`, payload),
  },
  private: {
    updateUser: (user) => ProtectedHttpClient.post(`/update-user/`, user),
  },
};
export default UserApi;
