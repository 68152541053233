import React from "react";
import { useSelector } from "react-redux";
import SurveyUtils from "../../survey-utils";

const SurveyQuestionViewer = () => {
  const { activeSurveyEditor } = useSelector((state) => state.survey);
  return (
    <>
      <div className="text-size-body text-body">Coming up next</div>

      <div className="question-list my-8">
        {activeSurveyEditor.questions?.map((question, questionIndex) => (
          <div
            key={questionIndex}
            className={`question-item grid gap-4 bg-gray-100 text-body hover:bg-secondary-dark cursor-pointer duration-200 text-size-body rounded-lg my-4 p-4 ${
              questionIndex === activeSurveyEditor.activeQuestionIndex &&
              "active"
            } ${question._hasAnswer && 'hasAnswer'}`}
            onClick={(e) =>
              SurveyUtils.selectNewQuestion({
                questionIndex,
              })
            }
          >
            <span>{questionIndex + 1}.</span>
            <span>{question.questionText}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default SurveyQuestionViewer;
