import React, { useEffect, useState } from "react";
import ICONS from "../../../../../icons-lib";
import AuthHelper from "../../../../../utils/auth-helper";
import { EventEmitter } from "../../../../../utils/event-emitter";
import GeneralUtils from "../../../../../utils/general-utils";
import "./header.scss";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else if (window.scrollY < 50) {
        setScroll(false);
      }
    });
  }, []);

  return (
    <>
      <div className="header-wrapper w-full sticky top-4 z-50">
        <div className="container mx-auto">
          <div
            className={
              "inner-wrapper w-full bg-white rounded-3xl block md:flex justify-between items-center duration-200 shadow " +
              (scroll ? "px-8 py-4" : "p-8")
            }
          >
            <div className="left flex items-center">
              <div
                className={
                  "logo duration-200 " + (scroll ? "w-8 h-8" : "w-12 h-12")
                }
              >
                <img
                  src={require("../../../../../images/logo.png")}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <div
                className={
                  "font-heading duration-200 " +
                  (scroll
                    ? "text-size-heading-small ml-4"
                    : "text-size-big ml-8")
                }
              >
                Welcome back!
              </div>
            </div>

            <div className="right block md:flex items-center">
              <div
                className={"button fancy " + (scroll ? "small mr-4" : "mr-8")}
              >
                {/* <div
                  className={
                    "image-wrapper overflow-hidden rounded-full " +
                    (scroll ? "w-8 h-8" : "w-12 h-12")
                  }
                >
                  <img
                    src="https://i.pravatar.cc/300"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div> */}
                <span
                  onClick={(e) => EventEmitter.dispatch("SHOW_PROFILE_EDITOR")}
                >
                  My profile
                </span>
                {ICONS.SVG_Arrow_curly}
              </div>
              <div className={"button " + (scroll ? "small" : "")} onClick={e => {
                GeneralUtils.showLoader();
                AuthHelper.logout();
                GeneralUtils.hideLoader();
                // window.location.reload();
              }}>
                <span>Logout</span>
                {ICONS.SVG_Arrow_simple}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
