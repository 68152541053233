import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  user: null,
  emailToVerify: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setEmailToVerify: (state, action) => {
      state.emailToVerify = action.payload;
    },
    clearEmailToVerify: (state, action) => {
      state.emailToVerify = "";
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearUser: (state) => {
      state.token = "";
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setToken,
  clearUser,
  setEmailToVerify,
  clearEmailToVerify,
} = userSlice.actions;

export default userSlice.reducer;
