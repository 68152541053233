import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import FullpageLoader from "./core/fullpage-loader/fullpage-loader";
import ProtectedRoute from "./core/route/protected-route/protected-route";
import PublicRoute from "./core/route/public-route/public-route";
import Dashboard from "./modules/student-portal/pages/dashboard/dashboard";
import EmailAuthVerifyPage from "./modules/student-portal/pages/email-auth-verify-page/email-auth-verify-page";
import LoginPage from "./modules/student-portal/pages/login-page/login-page";
import AuthHelper from "./utils/auth-helper";

function App() {
  useEffect(() => {
    AuthHelper.initialize();
  }, []);
  return (
    <div className="App">
      <Router>
        <Switch>
          <ProtectedRoute
            exact
            path="/home"
            component={Dashboard}
            redirectRoute={"/"}
          />
          <Route
            exact
            path="/handle-redirect-login"
            component={EmailAuthVerifyPage}
          />
          <PublicRoute
            exact
            path="*"
            component={LoginPage}
            redirectRoute={"/home"}
          />
        </Switch>
      </Router>
      <FullpageLoader />
      <ToastContainer />
    </div>
  );
}

export default App;
