import React, { Component, useState } from "react";
import ICONS from "../../../../../../icons-lib";
import SurveyUtils from "../../survey-utils";

const TeacherBlock = ({ teacher, teacherSurvey }) => {
  const [teacherSelect, setTeacherSelect] = useState(false);
  const status = SurveyUtils.getTeacherSurveyStatus({
    survey: teacherSurvey,
    teacher,
  });

  return (
    <>
      <div
        className={"teacher " + (teacherSelect ? "selected" : "")}
        onClick={(e) => setTeacherSelect(!teacherSelect)}
      >
        <div className="left">
          <div className="icon">{ICONS.SVG_teacher}</div>
          <div className="name">{teacher.teacherName}</div>
        </div>

        <div className="buttons flex items-center justify-end">
          {status.isStarted && !status.isCompleted ? (
            <div className="progress grid gap-4 w-1/2 mr-3">
              <div className="track rounded-full">
                <span
                  class="complete"
                  style={{
                    "--complete-percent": `${status.progressPercentage}%`,
                  }}
                ></span>
              </div>
              <span className="percent">{status.progressPercentage}%</span>
            </div>
          ) : (
            ""
          )}

          <div
            className="button fancy"
            onClick={(e) =>
              SurveyUtils.openTeacherSurvey({ survey: teacherSurvey, teacher })
            }
            style={
              status.isCompleted
                ? {
                    opacity: "0.2",
                    pointerEvents: "none",
                  }
                : null
            }
          >
            <span>
              {status.isStarted && !status.isCompleted && "Continue"}{" "}
              {status.isCompleted && "Complete!"} {!status.isStarted && "Start"}
            </span>
            {!status.isCompleted && ICONS.SVG_Arrow_curly}
          </div>
        </div>
      </div>
    </>
  );
};

const TeacherSelector = ({ teacherSurvey, isVisible, onDismiss }) => {
  return (
    <>
      {isVisible ? (
        <>
          <div className={"modal select-teacher-modal show"}>
            <div className="modal-inner">
              <div
                className="close-modal cursor-pointer flex justify-end"
                onClick={onDismiss}
              >
                {ICONS.SVG_close}
              </div>
              <div class="title font-heading text-3xl mb-3">
                Please select a Teacher
              </div>

              <div className="teacher-list">
                {teacherSurvey?.teachers?.map((teacher) => (
                  <TeacherBlock
                    teacherSurvey={teacherSurvey}
                    teacher={teacher}
                    key={teacher._id}
                  />
                ))}
                {/* <TeacherBlock />
                <TeacherBlock started={true} />
                <TeacherBlock />
                <TeacherBlock />
                <TeacherBlock />
                <TeacherBlock />
                <TeacherBlock /> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TeacherSelector;
